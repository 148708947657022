<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            label="년도"
            type="year"
            default="today"
            name="esgYear"
            @datachange="getList"
            v-model="searchParam.esgYear">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            required
            :comboItems="useQuarters"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="esgQuarter"
            label="분기"
            @datachange="getList"
            v-model="searchParam.esgQuarter"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridE1"
          title="총 에너지사용량"
          tableId="gridE1"
          :columns="gridE1.columns"
          :data="dataE1"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridE2"
          title="전기사용량"
          tableId="gridE2"
          :columns="gridE2.columns"
          :data="dataE2"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridE3"
          title="에너지사용량"
          tableId="gridE3"
          :columns="gridE3.columns"
          :data="dataE3"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridD1"
          title="대기질"
          tableId="gridD1"
          :columns="gridD1.columns"
          :data="dataD1"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridD2"
          title="온실가스-직접배출량"
          tableId="gridD2"
          :columns="gridD2.columns"
          :data="dataD2"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridD3"
          title="온실가스-간접배출량"
          tableId="gridD3"
          :columns="gridD3.columns"
          :data="dataD3"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridW1"
          title="폐기물 발생량"
          tableId="gridW1"
          :columns="gridW.columns"
          :data="dataW1"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridW2"
          title="폐기물 재활용율"
          tableId="gridW2"
          :columns="gridW.columns"
          :data="dataW2"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridC1"
          title="유해화학물질 사용량"
          tableId="gridC1"
          :columns="gridW.columns"
          :data="dataC1"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridA1"
          title="산업재해발생"
          tableId="gridA1"
          :columns="gridA1.columns"
          :data="dataA1"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridL1"
          title="법규준수"
          tableId="gridL1"
          :columns="gridL1.columns"
          :data="dataL1"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="gridW3"
          title="용수사용량"
          tableId="gridW3"
          :columns="gridW.columns"
          :data="dataW3"
          :isDashboard2="true"
          :usePaging="false"
          :isFullScreen="false"
          :filtering="false"
          :gridHeightAuto="true"
          :hideBottom="true"
          :columnSetting="false"
        >
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'esgManagementData',
  data() {
    return {
      searchParam: {
        esgYear: null,
        esgQuarter: null,
      },
      gridE1: {
        columns: [
          {
            name: 'esgManagementItemName',
            field: 'esgManagementItemName',
            label: '구분',
            align: 'center',
            sortable: false,
          },
          {
            name: 'quarters',
            field: 'quarters',
            label: '사용량',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'esgManagementItemUnitName',
            field: 'esgManagementItemUnitName',
            label: '단위',
            align: 'center',
            sortable: false,
          },
        ],
      },
      gridE2: {
        columns: [
          {
            name: 'esgManagementPointName',
            field: 'esgManagementPointName',
            label: '구분',
            align: 'center',
            sortable: false,
          },
          {
            name: 'quarters',
            field: 'quarters',
            label: '사용량',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'esgManagementItemUnitName',
            field: 'esgManagementItemUnitName',
            label: '단위',
            align: 'center',
            sortable: false,
          },
        ],
      },
      gridE3: {
        columns: [
          {
            name: 'esgManagementItemName',
            field: 'esgManagementItemName',
            label: '구분',
            align: 'center',
            sortable: false,
          },
          {
            name: 'quarters',
            field: 'quarters',
            label: '사용량',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'esgManagementItemUnitName',
            field: 'esgManagementItemUnitName',
            label: '단위',
            align: 'center',
            sortable: false,
          },
        ],
      },
      gridD1: {
        columns: [
          {
            name: 'esgManagementItemName',
            field: 'esgManagementItemName',
            label: '구분',
            align: 'center',
            sortable: false,
          },
          {
            name: 'quarters',
            field: 'quarters',
            label: '배출량',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'esgManagementItemUnitName',
            field: 'esgManagementItemUnitName',
            label: '단위',
            align: 'center',
            sortable: false,
          },
        ],
      },
      gridD2: {
        columns: [
          {
            name: 'esgManagementItemName',
            field: 'esgManagementItemName',
            label: '구분',
            align: 'center',
            sortable: false,
          },
          {
            name: 'quarters',
            field: 'quarters',
            label: '배출량',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'esgManagementItemUnitName',
            field: 'esgManagementItemUnitName',
            label: '단위',
            align: 'center',
            sortable: false,
          },
        ],
      },
      gridD3: {
        columns: [
          {
            name: 'esgManagementPointName',
            field: 'esgManagementPointName',
            label: '구분',
            align: 'center',
            sortable: false,
          },
          {
            name: 'quarters',
            field: 'quarters',
            label: '배출량',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
          {
            name: 'esgManagementItemUnitName',
            field: 'esgManagementItemUnitName',
            label: '단위',
            align: 'center',
            sortable: false,
          },
        ],
      },
      gridA1: {
        columns: [
          {
            name: 'esgManagementItemName',
            field: 'esgManagementItemName',
            label: '구분',
            align: 'center',
            sortable: false,
          },
          {
            name: 'quarters',
            field: 'quarters',
            label: '건수',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
        ],
      },
      gridL1: {
        columns: [
          {
            name: 'esgManagementItemName',
            field: 'esgManagementItemName',
            label: '구분',
            align: 'center',
            sortable: false,
          },
          {
            name: 'quarters',
            field: 'quarters',
            label: '건수',
            align: 'right',
            type: 'cost',
            sortable: false,
          },
        ],
      },
      gridW: {
        columns: [],
      },
      useQuarters: [
        { code: '1', codeName: '1분기' },
        { code: '2', codeName: '2분기' },
        { code: '3', codeName: '3분기' },
        { code: '4', codeName: '4분기' },
      ],
      editable: true,
      listUrl: '',
      datas: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  computed: {
    dataE1() { // 총에너지 사용량
      return this.$_.filter(this.datas, {itemType : 'E1' })
    },
    dataE2() { // 전기사용량  
      return this.$_.filter(this.datas, {itemType : 'E2' })
    },
    dataE3() { // 에너지 사용량 (전기사용량 제외)  
      return this.$_.filter(this.datas, {itemType : 'E3' })
    },
    dataD1() { // 대기질
      return this.$_.filter(this.datas, {itemType : 'D1' })
    },
    dataD2() { // 온실가스배출량-직접배출량
      return this.$_.filter(this.datas, {itemType : 'D2' })
    },
    dataD3() { // 온실가스배출량-간접배출량 
      return this.$_.filter(this.datas, {itemType : 'D3' })
    },
    dataW1() { // 폐기물발생량
      return this.$_.filter(this.datas, {itemType : 'W1' })
    },
    dataW2() { // 폐기물 재활용율
      return this.$_.filter(this.datas, {itemType : 'W2' })
    },
    dataC1() { // 유해화학물질 사용량
      return this.$_.filter(this.datas, {itemType : 'C1' })
    },
    dataA1() { // 산업재해발생
      return this.$_.filter(this.datas, {itemType : 'A1' })
    },
    dataL1() { // 법규준수
      return this.$_.filter(this.datas, {itemType : 'L1' })
    },
    dataW3() { // 용수사용량
      return this.$_.filter(this.datas, {itemType : 'W3' })
    },
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sai.esg.data.list.url;
      this.getList();
    },
    getList() {
      if (this.searchParam.esgYear && this.searchParam.esgQuarter) {
        this.gridW.columns = [];
        var _i = 1;
        switch(this.searchParam.esgQuarter) {
          case '1':
            _i = 1;
            break;
          case '2':
            _i = 4;
            break;
          case '3':
            _i = 7;
            break;
          case '4':
            _i = 10;
            break;
        }
        var _j = 1;
        for(var i=_i; i<(_i + 3); i++) {
          this.gridW.columns.push(
            {
              name: 'month' + '0' + _j,
              field: 'month' + '0' + _j,
              label: i + '월',
              align: 'right',
              type: 'cost',
              sortable: false,
            }
          )
          _j++;
        }
        this.gridW.columns.push(
          {
            name: 'esgManagementItemUnitName',
            field: 'esgManagementItemUnitName',
            label: '단위',
            align: 'center',
            sortable: false,
          }
        )

        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.datas = _result.data;
        },);
      } else {
        this.datas = [];
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '년도와 분기를 선택하세요', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      }
    },
  },
};
</script>
